<template>
  <div class="app">
    <div v-if="showPage">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import './constant'
// import store from '@/store/index'
import { mapGetters } from 'vuex'
// import { setUserAuth, getUser } from '@/scripts/userAuth'
import wxSDK from '@/scripts/wxsdk'
export default {
  data() {
    return {
      inited: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    showPage() {
      const auth_type = global.APP_CONFIG.auth_type
      const flag =
        auth_type === 'customAuthLogin' ||
        ((auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') &&
          this.userInfo)
      return flag
    }
  },
  watch: {
    async $route(_to, _from) {
      if (!this.inited) {
        await this.init()
      }
    }
  },
  async created() {
    // this.demo()
    await this.init()
  },
  methods: {
    demo() {
      console.log('demo:authorizeInfo', this.authorizeInfo)
      console.log('demo:userInfo', this.userInfo)
    },
    async init() {
      // console.log('created:init:getUsersCurrentInfo', store.getters['userAuth/getUsersCurrentInfo'])
      // if (!store.getters['userAuth/getUsersCurrentInfo']) {
      //   const params = { authType: 'webAuthLogin' }
      //   await setUserAuth(params)
      // }
      // await getUser()
      await wxSDK.config([], ['shareAppMessage'])
      this.inited = true
    }
  }
}
</script>

<style lang="less" scoped>
.app {
  height: 100vh;
  background: #fff;
}
</style>
